import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import paulHuvenne from "../images/paulhuvenne.jpeg";
import { FaEnvelope } from "react-icons/fa";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`Paul`, `Huvenne`, `Beelddenken`, `kmska`]}
      />

      <div className="text-center">
        <img
          src={paulHuvenne}
          className="block mx-auto w-1/2 rounded"
          alt="Paul Huvenne"
        />

        <h2 className="inline-block my-8 p-3">
          Paul Huvenne
          <div>
            <a href="mailto:info@paulhuvenne.be" className="text-blue"><FaEnvelope/></a>


          </div>

        </h2>

        <p className="leading-loose">
            Paul Huvenne is an art historian with a considerable track record, having directed both the Rubens house and the Museum of Fine Arts in Antwerp for many years. He can share his experience and expertise with you to realize your project.
        </p>
      </div>
    </Layout>
  );
}

export default IndexPage;
